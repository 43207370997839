// Exemplo:
// <select name="teste" class="form-select" x-data="Autocomplete({data: [{id: 5, nome:'coisa1'},{id: 9, nome:'coisa 2'}], field_value: 'id', field_label:'nome'})"></select>

import Choices from "choices.js";
import { template, templateSettings, omitBy, isNil } from 'lodash';

// Configura template do lodash ex: "Nome: {{name}}"
templateSettings.interpolate = /\{\{([\s\S]+?)\}\}/g;

// PARAMETROS:
// data: opçoes fixas, não utiliza url
// url: url do serviço que deve responder um json array
// field_label: coluna que representa a descrição exibida
// field_value: coluna que representa o valor
// selected_value: valor do campo informado em value
// sort: Ordenação do resultado, ex: 'id asc'
// q: coluna ransack de pesquisa. Padrão: search_i_cont_all
// char_limit: Digitos para iniciar a busca
// use_q_selected: considera o critério de 'q' para buscar o valor selecionado.
// 
// EVENTOS:
// @autocomplete-change-[id do select].window:  Caso o id seja separado por _ será subistituido por -. 
// Ex: id="my_id" logo o evento será: @autocomplete-change-my-id
// 
export default (args = { data: '', url: '', field_label: '', field_value: '', selected_value: '', sort: '', q: '', char_limit: '', use_q_selected: '' }) => ({
  _url: args.url,
  _data: args.data,
  _label: args.field_label || 'label',
  _value: args.field_value || 'value',
  _selected: args.selected_value || '',
  _sort: args.sort || '',
  _q: args.q || 'search_i_cont_all',
  _char_limit: args.char_limit || 1,
  _use_q_selected: args.use_q_selected || false,
  _choices: null,
  _params: {}, // querystring params

  init() {
    // Com url, deve buscar na api
    if (this._url && !(Array.isArray(this._data) /*&& this._data.length*/)) {
      this.$root.addEventListener("search", Alpine.debounce(this.handleSearch.bind(this), 250));
    }

    // Propaga o evento 'change' para ser ouvido por outro componente
    let eventId = (this.$root.id || String(Math.floor(Math.random() * 9999))).replaceAll('_', '-');
    if (eventId.endsWith('-')) {
      eventId = eventId.slice(0, -1);
    }
    this.$root.addEventListener("change", (event) => {
      // Mantem o valor do input hidden atualizado, necessário quando disabled.
      document.querySelectorAll(`#${this.$el.id}_hidden`).forEach((el) => { el.value = this.$el.value });
      const eventName = `autocomplete-change-${eventId}`;
      console.log('Evento:', eventName, 'Valor:', this.getValue());
      this.$dispatch(eventName, this.getValue());
    });

    this.$root.addEventListener("removeItem", (event) => {
      this.$dispatch("remove-item", { value: event.detail.value });
    });


    this._fetchConfig = {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    };

    this.$nextTick(() => {
      this._choices = new Choices(this.$el, {
        silent: false,
        items: [],
        choices: [],
        renderChoiceLimit: -1,
        maxItemCount: -1,
        addItems: true,
        addItemFilter: null,
        removeItems: true,
        removeItemButton: true,
        editItems: false,
        allowHTML: true,
        duplicateItemsAllowed: true,
        delimiter: ',',
        paste: true,
        searchEnabled: true,
        searchChoices: true,
        searchFloor: 1,
        searchResultLimit: 20, // TODO: Add parâmetro
        searchFields: ['label', 'value'],
        position: 'auto',
        resetScrollPosition: true,
        shouldSort: true,
        shouldSortItems: false,
        sorter: () => { },
        placeholder: true,
        placeholderValue: null,
        searchPlaceholderValue: null,
        prependValue: null,
        appendValue: null,
        renderSelectedChoices: 'auto',
        loadingText: 'Carregando...',
        noResultsText: 'Nenhum resultado encontrado',
        // noResultsText: `<a href="#" @click.prevent="alert('TOP ZERA');">Adicionar</a>`,
        noChoicesText: 'Sem opções para escolher',
        itemSelectText: '',
        addItemText: (value) => {
          return `Pressione Enter para adicionar <b>"${value}"</b>`;
        },
        maxItemText: (maxItemCount) => {
          return `Apenas ${maxItemCount} itens são permitidos`;
        },
        valueComparer: (value1, value2) => {
          return value1 === value2;
        },
        // Choices uses the great Fuse library for searching. You
        // can find more options here: https://fusejs.io/api/options.html
        fuseOptions: {
          includeScore: true
        },
        labelId: '',
        callbackOnInit: function () {
          // Ao montar o elemento,
          // E existir a div.invalid-feedback,
          // Adicionar as classes de erro.
          // this.containerOuter -> div.choices
          const siblingEl = this.containerOuter.element.nextElementSibling;
          if (siblingEl && siblingEl.classList.contains('invalid-feedback')) {
            this.containerOuter.element.classList.add('is-invalid')
            this.containerInner.element.classList.add('ch-is-invalid');
          }
        },
        callbackOnCreateTemplates: null
      });

      this.setData();
      // Regra: Quando exisistir x-init no autocomplete a seleção inicial do item é ignorada.
      if (!this.$el.hasAttribute('x-init')) {
        this.setSelected(this._selected, {}, { disableChange: true });
      }

      // Adiciona input hidden para manter o valor do campo, quando disabled
      this.$el.insertAdjacentHTML('beforebegin', `<input type="hidden" id="${this.$el.id}_hidden" name="${this.$el.name}" value="${this._selected}">`);
    });
  },

  /**
   * Verifica se o select é multiplo ou simples
   * @returns boolean
   */
  isMultiple() {
    return this.$el.multiple;
  },

  /**
   * Adiciona a querystring na url para ser considerada na pesquisa do autocomplete
   * @param {Object} qs 
   */
  setParams(params) {
    this._params = params;
  },

  /**
 * Adiciona sucessivos params ao mesmo objeto para pesquisas que dependem de mais de uma origem de dados
 * Retorna os dados quando solicitado
 * @returns Object
 */
  assignParams(params) {
    return this._params = Object.assign(this._params, omitBy(params, isNil));
  },

  /**
   * Obtem o objeto original selecionado
   * @returns Object
   */
  getValue() {
    return (this._choices.getValue() || { customProperties: {} }).customProperties;
  },


  /**
   * Remove todos os itens selecionados
   */
  removeValue() {
    this._choices.removeActiveItems();
    //this._choices.removeActiveItemsByValue(this._choices.getValue(true));
    this.setHiddenValue('');
  },


  /**
   * Seta uma coluna label em cada objeto do resultado, 
   * concatenando as descrições usadas na exibição.
   * @param {Array} items 
   * @returns {Array} items
   */
  setLabelColumn(items) {
    const labels = this._label.split(',');

    const usaTemplate = this._label.includes('{{');

    items.forEach((item) => {
      let labelValue = '';

      if (usaTemplate) {
        labelValue = template(this._label)(item);
      } else {
        let labelValues = [];
        labels.forEach((field) => {
          const v = String(item[field.trim()]);
          if (field && v.length > 0) {
            labelValues.push(v);
          }
        });
        labelValue = labelValues.filter(m => m.trim()).join(' - ');
      }

      item['customProperties'] = Object.assign({}, item);
      item['label'] = labelValue;
    });

    return items;
  },

  /**
   * Executa busca com os parametros informados.
   * @param {*} params 
   */
  searchParams(params = {}, opt = { selectedFirst: false, clearAfterSearch: false }) {
    this.setParams(params);
    this.handleSearch(false, opt);
    if (opt.clearAfterSearch) {
      this.setParams({});
    }
  },

  /**
   * Callback do evento 'search' do choices.
   * Este métdo é quem faz a busca ao digitar
   * @param {CustomEvent} e 
   * @returns 
   */
  handleSearch(e = false, opt = {}) {
    if (e && !this._url) {
      console.error('URL não informada!');
      return;
    }

    const search = e && e.detail.value;

    if (e && search.trim().length < this._char_limit) {
      this._choices.clearChoices();
      return;
    }

    let params = {};
    if (e) {
      params[this._q] = search;
    }
    // let url = `${this._url}?q[${this._q}]=${encodeURIComponent(search)}`;

    // Adiciona parametro para ordenação
    if (this._sort) {
      // url += `&q[s]=${encodeURIComponent(this._sort)}`;
      params['s'] = this._sort;
    }


    // Adiciona parametros adicionais na url
    if (this._params && Object.keys(this._params).length) {
      params = Object.assign(params, this._params);
    }

    // Monta url
    const qs = Object.keys((params)).map(key => `q[${key}]=${encodeURIComponent(params[key])}`).join('&');
    let url = `${this._url}?${qs}`;


    fetch(url, this._fetchConfig)
      .then(response => response.json())
      .then(items => {
        items = this.setLabelColumn(items);
        this._choices.setChoices(items, this._value, 'label', true);
        this._choices.setValue(items);

        if (opt.selectedFirst && items.length == 1) {
          items.forEach((it) => {
            this._choices.setChoiceByValue(it[this._value]);
          });
          this.$dispatch('change', { value: items[0][this._value] });
        }
      })
      .catch((e) => { console.log(e); });
  },

  setData() {
    if (this.hasData()) {
      let items = this.setLabelColumn(this._data);
      this._choices.setChoices(items, this._value, 'label', true);
    }
  },

  hasData() {
    return Array.isArray(this._data) && this._data.length;
  },

  /**
   * Seta o valor atual ao iniciar o componente.
   * @param {*} selected - Valor ou array de valores a serem setados
   * @param { disableChange: false} options - Desabilita o evento change
   * @returns 
   */
  setSelected(selected, params = {}, options = { disableChange: false }) {
    // TODO: Rever necessidade desta validação e rever this._choices.setChoiceByValue(it[this._value]); no resultado do fetch
    let vals = Array.of(selected).flat().reduce((m, v) => { v && m.push(v); return m; }, []);
    if (vals.length == 0) {
      return;
    }

    // Mantem o valor do input hidden atualizado, necessário quando disabled    
    this.setHiddenValue(selected);

    if (this.hasData()) {
      Array.of(selected).flat().forEach((it) => {
        let v = this.prepareChoiceValue(it);
        this._choices.setChoiceByValue(v);
      });
      return;
    }

    if (!this._url) {
      return;
    }

    let qs = [];
    Array.of(selected).flat().forEach((s) => {
      qs.push(`q[${this._value}_in][]=${encodeURIComponent(s)}`)

      // Quando o value selecionado não for id unico é necessário considerar o critério de busca, ex: placa dos conjuntos.
      if (this._use_q_selected) {
        qs.push(`q[${this._q}][]=${encodeURIComponent(s)}`)
      }
    });

    // Aplica os parametros se existir
    if (Object.keys(params).length) {
      this.setParams(params);
    }

    // Atribui os params na querystring
    if (this._params && Object.keys(this._params).length) {
      Object.keys(this._params).forEach(key => {
        qs.push(`q[${key}]=${encodeURIComponent(this._params[key])}`)
      });
    }

    const url = `${this._url}?${qs.join("&")}`;
    fetch(url, this._fetchConfig)
      .then(response => response.json())
      .then(items => {
        if (items && items.length) {
          items = this.setLabelColumn(items);
          this._choices.setChoices(items, this._value, 'label', true);

          items.forEach((it) => {
            this._choices.setChoiceByValue(it[this._value]);
          });

          // 
          if (!options.disableChange) {
            // Dispara o change quando o valor é setado programaticamente (sem o click do usuário)
            if (this.isMultiple()) {
              this.$dispatch('change', { value: items.map((it) => { return it[this._value] }) });
            } else {
              this.$dispatch('change', { value: items[0][this._value] });
            }
          }
        }
      })
      .catch((e) => { console.log(e); });

  },

  setHiddenValue(value = '') {
    document.querySelectorAll(`#${this.$el.id}_hidden`).forEach((el) => { el.value = value });
  },

  setEnable() {
    this._choices.enable();
  },

  setDisable() {
    this._choices.disable();
  },

  /**
   * Formata o valor conforme o tipo do campo
   * @param {*} value 
   * @returns value
   */
  prepareChoiceValue(value) {
    let dataType = null;
    if (this._data && this._data.length) {
      dataType = typeof this._data[0][this._value];
    }

    if (dataType == 'number') {
      return Number(value);
    } else if (dataType == 'string') {
      return String(value).trim();
    }

    return value;
  },

  getBinding() {
    return this;
  }
});
