/**
 * O generator irá registrar os componentes,
 * imediatamente antes de iniciar o Alpine.
 */
import Alpine from 'alpinejs'
window.Alpine = Alpine

import focus from '@alpinejs/focus'
Alpine.plugin(focus)

import mask from '@alpinejs/mask'
Alpine.plugin(mask)

import TurboFetchMagic from './turbo_fetch_magic'
Alpine.magic('turboFetch', TurboFetchMagic)

import ModalData from "./modal_data"
Alpine.data("Modal", ModalData)

import TimeoutData from "./timeout_data"
Alpine.data("Timeout", TimeoutData)

import MomentData from "./moment_data"
Alpine.data("Moment", MomentData)

import AutocompleteData from "./autocomplete_data"
Alpine.data("Autocomplete", AutocompleteData)

import Logistica_FormacaoCargas_ModalMonisatData from "./logistica/formacao_cargas/modal_monisat_data"
Alpine.data("Logistica_FormacaoCargas_ModalMonisat", Logistica_FormacaoCargas_ModalMonisatData)

import NumeralData from "./numeral_data"
Alpine.data("Numeral", NumeralData)

import Rh_AtualizacaoCadastral_CropperData from "./rh/atualizacao_cadastral/cropper_data"
Alpine.data("Rh_AtualizacaoCadastral_Cropper", Rh_AtualizacaoCadastral_CropperData)
        
Alpine.start()
