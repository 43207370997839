// Usage: <div x-data="Logistica_FormacaoCargas_ModalMonisat"></div>

export default (args = { rotaId: '', monitoramentoId: '', locais: [], entregas: [], mapaUrl: '' }) => ({

  rotaId: args.rotaId,

  init() {
    this._mostrando = false;
    const self = this;
    Sortable.create(this.$refs.bodyLocais, {
      handle: '.bi-arrows-move',
      animation: 200,
      onEnd: function (evt) {
        console.log('arrastou', evt);
        self.$dispatch('change-mapa', {});
      }
    });

    Sortable.create(this.$refs.bodyEntregas, {
      handle: '.bi-arrows-move',
      animation: 200,
      onEnd: function (evt) { }
    });
  },

  get monitoramentoId() {
    return args.monitoramentoId;
  },

  get locais() {
    return args.locais;
  },

  get entregas() {
    return args.entregas;
  },

  get mapaUrl() {
    return args.mapaUrl;
  },

  get mostrando() {
    return this._mostrando;
  },

  set mostrando(value) {
    this._mostrando = value;
  },


  montaMapa(valor) {
    let origem_id = valor.id == 'rota_origem_endereco_id' ? valor.value : document.getElementById('rota_origem_endereco_id').value;
    let destino_id = valor.id == 'rota_destino_endereco_id' ? valor.value : document.getElementById('rota_destino_endereco_id').value;

    // Exclusão clicando no x do autocomplete
    if (valor.id == 'rota_origem_endereco_id' &&
      !document.getElementById('rota_origem_endereco_id').value) {
      origem_id = '';
    }

    if (valor.id == 'rota_destino_endereco_id' &&
      !document.getElementById('rota_destino_endereco_id').value) {
      destino_id = '';
    }

    let locais = document.getElementsByName('rota[locais][]');

    let locais_passagem = [];

    if (valor.id == 'locais') {
      locais_passagem = valor.value.map((local) => local.codigo_ibge);
    } else {
      for (var i = 0; i < locais.length; i++) {
        locais_passagem.push(locais[i].value);
      }
    }

    fetch(this.mapaUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        rota_origem: origem_id,
        rota_destino: destino_id,
        locais_passagem: locais_passagem
      })
    })
      .then(response => response.text())
      .then(html => {
        const results = document.querySelector('#mapa');
        results.innerHTML = html;
      })
  }

})
