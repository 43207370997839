import {Modal} from 'bootstrap'

//Usage: <div class="modal" x-data="Modal"></div>
export default () => ({
  showing: false, // usado para ativar o modal.
  focus: false,   // usado para ativar o foco depois que o modal estiver complentamente aberto.
  backdrop: false, // usado para ativar o backdrop estático. (não fecha ao clicar fora ou pressionar 'esc' do modal)
  escapeHandler: null, // usado para ativar o fechamento do modal ao pressionar 'esc'

  init() {
    this.$el.setAttribute('x-on:hidden-bs-modal.dot', "handleHiddenBsModal")
    this.$el.setAttribute('x-on:shown-bs-modal.dot', "focusModal")    
    this.backdrop = this.$el.getAttribute('data-bs-keyboard') === "false" ? false : true;

    if (this.backdrop) {
      this.escapeHandler = (event) => { if (event.key === 'Escape') this.close() }
      document.body.addEventListener('keydown', this.escapeHandler)
    }
    
    this.$watch('showing', (value, oldValue) => {
      if (value && !oldValue) {
        this.open()        
      }
    })

    this.modal = new Modal(this.$root, {
      keyboard: this.backdrop
    })

    this.$nextTick(() => { this.$dispatch('modal:init') })    
  },

  open() {
    this.modal.show()
  },

  
  close() {
    this.modal.hide()
  },

  closeAll() {
    document.querySelectorAll('[data-bs-dismiss=modal]').forEach(el => el.offsetWidth > 0 && el.offsetHeight > 0 && el.click())    
  },

  destroy() {
    this.closeAll()
    this.$root.remove()
  },

  handleHiddenBsModal() {
    this.showing=false; 
    this.focus=false
    this.$root.removeAttribute('x-init')
  },
  
  focusModal() {
    const focuFields = [...this.$el.querySelectorAll('input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])')]

    if (focuFields.length > 0) {
      focuFields[0].focus();
    }
  },

  disableBackdrop() {
    if (this.backdrop) {
      this.backdrop = false
      this.modal._config.backdrop = 'static'
      this.modal._config.keyboard = false

      if (this.escapeHandler) {
        document.body.removeEventListener('keydown', this.escapeHandler)
        this.escapeHandler = null
      }
    }
  }



})
