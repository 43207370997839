// Usage: <div x-data="Rh_AtualizacaoCadastral_Cropper"></div>
import Cropper from 'cropperjs';

export default () => ({
  imageUrl: null,
  cropper: null,
  croppedCanvas: null,

  hiddenCropper: true,
  hiddenBtnCropper: false,
  hiddenPreviewImage: true,
  hiddenConfirmImage: false,

  init() {},

  handleFileChange(e) {

    const files = this.$el.files;
    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        this.imageUrl = event.target.result;
        this.$refs.preview_image.src = this.imageUrl;

        // Destroi qualquer cropper anterior antes de criar um novo
        if (this.cropper) {
          this.cropper.destroy();
          this.cropper = null;
        }

        this.cropper = new Cropper(this.$refs.preview_image, {
          aspectRatio: 1,
          viewMode: 3,
        });

        this.hiddenCropper = false;
        this.hiddenBtnCropper = true;
      };
      reader.readAsDataURL(file);
    }
  },

  handleCropper(e) {
    this.croppedCanvas = this.cropper.getCroppedCanvas();
    const canvas = this.$refs.canvas_crop;

    this.hiddenPreviewImage = false;
    this.hiddenBtnCropper = false;
    this.hiddenConfirmImage = true;

    canvas.style.display = 'block';
    canvas.width = this.croppedCanvas.width;
    canvas.height = this.croppedCanvas.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(this.croppedCanvas, 0, 0);

    this.setFileCropper();

    
  },

  setFileCropper() {
    this.$refs.inputFile.value = "";

    croppedCanvas.toBlob((blob) => {
      const file = new File([blob], 'foto_colaborador.png', { type: 'image/png' });

      const transfer = new DataTransfer();
      transfer.items.add(file);

      this.$refs.inputFile.files = transfer.files;
    }, 'image/png');

    this.cropper.destroy();
    this.cropper = null;
  }
});
