// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"

import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap

import moment from 'moment';
import 'moment/locale/pt-br'
moment.locale('pt-br')
window.moment = moment;

import numeral from "numeral";
import "numeral/locales/pt-br";
numeral.locale('pt-br');
window.numeral = numeral;


import Sortable from 'sortablejs';
window.Sortable = Sortable;

import "./alpinejs"

// Fetch interceptor
const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
  let [resource, config ] = args;
  
  const csrfToken = document.getElementsByName("csrf-token")[0].content;

  if (typeof(config) !== 'object') {
    config = { headers: {} };
  }  

  if (typeof(config['headers']) !== 'object') {
    config['headers'] = {}; 
  }
  
  config.headers['X-CSRF-Token'] = csrfToken;

  const response = await originalFetch(resource, config);
  
  return response;
};

// Remove querystring jwt
const params = new URLSearchParams(window.location.search);
if (params.has('jwt')) {
  const a = Array.from(params).length == 1 ? '?' : '';
  const remover = `${a}jwt=${params.get('jwt')}`;
  window.history.replaceState('', '', window.location.href.replace(remover, '').replace('?&','?'));
}


// Enable tootip
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
[...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));

document.addEventListener("turbo:before-fetch-request", (evt)=>{
  document.body.classList.add("loading");
});

document.addEventListener("turbo:submit-end", (evt)=>{
  document.body.classList.remove("loading");
});

document.addEventListener("turbo:frame-load", (evt)=>{
  document.body.classList.remove("loading");
});

document.addEventListener("turbo:render", (evt)=>{
  document.body.classList.remove("loading");
});


// import { Notyf } from 'notyf'
// window.notyf = new Notyf()

// import Alpine from 'alpinejs'

// import intersect from '@alpinejs/intersect'
// Alpine.plugin(intersect)

// window.Alpine = Alpine

// import crud from './crud.js'
// Alpine.data('Crud', crud)


// Alpine.data('SearchData', (initialKey = '', initialValue = '') => ({

//   key: initialKey, // querystring key
//   value: initialValue, // querystring value

//   init() {
//   },

//   _perform() {
//     const url = new URL(window.location);
//     url.searchParams.set(this.key, this.value);
//     if (!this.value) url.searchParams.delete(this.key)
//     window.history.pushState({}, '', url);

//     this.$refs.search.requestSubmit();
//   },

//   input: {
//     ['@keyup.debounce'](e) {
//       this.value = e.target.value
//       this._perform()
//     },
//     [':value']: 'value',
//   },

//   submit: {
//     ['@click.prevent']() {
//       this._perform()
//     }
//   },

// }))

// Alpine.data('DuvidasSearch', () => ({
//   init() {
//     console.log('init');
//   },

//   perform() {
//     this.$refs.search.requestSubmit();
//   }

// }))


// CUSTOM
// Alpine.directive('log', (el, { expression }, { evaluateLater, effect }) => {
//   let getThingToLog = evaluateLater(expression)

//   effect(() => {
//     getThingToLog(thingToLog => {
//       console.log(thingToLog)
//     })
//   })
// })


// Alpine.magic('now', () => {
//   return (new Date).toLocaleTimeString()
// })

/**
 * Magic funcition for querystring 
 * ex: <div x-data="{msg: $qs('msg', 'my message')}"></div>
 * then, htt//....?msg=my message
 */
// Alpine.magic('qs', () => {
//   return ((qsKey, qsValue = '') => {
//     const url = new URL(window.location);
//     url.searchParams.set(qsKey, qsValue);
//     if (!qsValue) url.searchParams.delete(qsKey)
//     window.history.pushState({}, '', url);
//     return qsValue
//   })
// })

/** DESCONTINUADO
 * 
 * Directive to querystring history
 * 
 * usage: x-qs="{key: value}"
 * 
 * ex: <input  x-data={'message': 'my-message'} x-model="message", x-qs="{'q': message}">
 * 
 * result: http://...?q=my-message
 * 
 */
// Alpine.directive('qs', (el, { expression }, { evaluateLater, effect }) => {
//   let getQueryString = evaluateLater(expression)

//   effect(() => {
//     getQueryString(keyVal => {
//       const [[qsKey, qsValue]] = Object.entries(keyVal)
//       const url = new URL(window.location);
//       url.searchParams.set(qsKey, qsValue);
//       if (!qsValue) url.searchParams.delete(qsKey)
//       window.history.pushState({}, '', url);
//     })
//   })
// })




/**
 * Abre modal no click sem interromper a propagação.
 * Por padrão utiliza turbo_frame 'form' 
 * 
 * Utilizar no link com destino ao modal.
 * 
 * Ex: <a href="#" x-bind="ShowModalForm">Abrir Modal</a>
 * 
 * Por padrão o este bind insere o html do form no body caso não exista.
 * Somente para o target padrão.
 */
// Alpine.bind('ShowModalForm', (_target = "#modal-form", _frame = 'form') => ({
//   'x-init'() {
//     if (_frame) {
//       this.$el.setAttribute('data-turbo-frame', _frame)
//     }

//     const el = document.querySelector(_target)
//     if (!el && _target == "#modal-form") {
//       document.body.insertAdjacentHTML("beforeend", '<div x-data="ModalForm"></div>')
//     }
//   },  
//   '@click'(evt) {
//     if (!_frame) {
//       evt.preventDefault()
//     }
//     const el = document.querySelector(_target)
//     bootstrap.Modal.getOrCreateInstance(el, { backdrop: 'static', keyboard: false }).show()
//     this.$dispatch('modal-form-title', 'Titulo do modal')
//   },
// }));


/** TODO: COMPONENTIZAR COM TR-MENU
 * 
 * Fecha um modal quando iniciado na tela via click em data-bs-dismiss.
 * Atribui css em tr table-visible quando ação disparado do menu da tabele.
 * 
 * O código de exemplo a baixo deve estar dentro da célula do menu da table.
 *  
 * ex: <div x-bind="CloseModalForm"></div>
 */
// Alpine.bind('CloseModalForm', (target = "#modal-form") => ({
//   'x-init'() {    
//     const closes = document.querySelectorAll('[data-bs-dismiss]')
//     closes.forEach((close) =>{
//       const isVisible = close && close.offsetWidth > 0 && close.offsetHeight > 0
//       if (close && isVisible) {
//         const tr = this.$el.closest('tr')
//         tr.classList.add('table-active')      
//         close.click()
//         this.$el.remove()
        
//         setTimeout(() => {
//           tr.classList.remove('table-active')
//         }, 10000);
//       }
//     });
//   }
// }));



/** ok
 * Componente ModalForm - bind
 * Define uma div como modal form do bootstrap.
 * Por padrão utiliza turbo_frame 'form' 
 * 
 * Ex: <div x-bind="ModalForm"></div>
 * 
 * Para acionar o modal, utilizar o x-bind ShowModalForm.
 * 
 */
// Alpine.bind('ModalForm', (_id="modal-form", _frame="form") => ({
//   id: _id,
//   class: 'modal fade',

//   // Define escopo de componente
//   'x-data'() {
//     return { name: '', title: '' }
//   },

//   'x-init'() {
//     let frame = `<turbo-frame id="${_frame}">Carregando...</turbo-frame>`
//     if (!_frame) {
//       frame = this.$el.innerHTML || ''
//     }
//     let h = `
//       <div class="modal-dialog">
//         <div class="modal-content">
//           <div class="modal-header">
//             <h5 class="modal-title" x-text="title"></h5>
//             <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//           </div>
//           <div class="modal-body pb-4">
//             ${frame}
//           </div>
//         </div>
//       </div>            
//     `
//     this.$el.innerHTML = h
//   },


//   '@modal-form-title.document'(evt) {
//     this.title = evt.detail
//   },

//   // Evento de fechamento do modal
//   '@hide-bs-modal.dot'() { },

// }));

/** ok
 * Componente ModalForm - data
 * 
 * Define uma div como modal form do bootstrap.
 * Por padrão utiliza turbo_frame 'form' 
 * 
 * Ex: <div x-bind="ModalForm"></div>
 * 
 * Para acionar o modal, utilizar o x-bind ShowModalForm. */
// Alpine.data('ModalForm', () => ({
//   name: '',
//   title: '',

//   init() {
//     this.$root.id = 'modal-form'
//     this.$root.classList = 'modal fade'
//     this.$root.setAttribute('x-bind', 'bind')
//     this.html()
//   },

//   html() {
//     let h = `
//       <div class="modal-dialog">
//         <div class="modal-content">
//           <div class="modal-header">
//             <h5 class="modal-title" x-text="title"></h5>
//             <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//           </div>
//           <div class="modal-body pb-4">
//             <turbo-frame id="form">
//                 Carregando...
//             </turbo-frame>
//           </div>
//         </div>
//       </div>            
//     `
//     this.$el.innerHTML = h
//   },

//   bind: {    
//     ['@modal-form-title.document'](evt) {
//       this.title = evt.detail
//     },

//     // Evento de fechamento do modal
//     ['@hide-bs-modal.dot']() {}

  
//   }

// }))

// // TODO: Ver se usa DATA ou BIND.
// Alpine.bind('SearchForm', () => ({
//   'x-init'() {
//     this.$el.setAttribute('data-turbo-frame', 'search')
//   },
//   '@hide-bs-modal.dot'() {
//     console.log('modal-name', this.name)
//   },
// }));



/**
 * Cria links de ação do menu crud da table
 * 
 * Modificadores:
 *  - x-action - Atribui turbo-frame=form, e segue o link
 *  - x-action.delete - Envia delete para o server
 * 
 */
// Alpine.directive('table-delete', (el, {value, modifiers, expression }, { evaluate, evaluateLater, effect, cleanup }) => {
  
//     el.classList.add('btn', 'btn-sm', 'btn-danger', 'mb-1')
//     el.setAttribute('data-turbo-frame', 'search')  
//     el.setAttribute('data-turbo-method', 'delete')  
//     el.setAttribute('data-turbo-confirm', evaluate(expression))
// })

// Alpine.directive('table-action', (el, {value, modifiers, expression }, { evaluate, evaluateLater, effect, cleanup }) => {
//   el.classList.add('btn', 'btn-sm', 'mb-1')
  
//   if (modifiers.length == 0) {
//     el.classList.add('btn-primary')
//   } else {
//     modifiers.forEach(element => {
//       el.classList.add(`btn-${element}`)
//     });
//   }

//   el.setAttribute('data-turbo-frame', 'form')  
//   el.setAttribute('x-bind', 'ShowModalForm')  
// })


// Alpine mínimo necessário ###############################################

/** ok
 * Intercepta o click e modifica requisição para turbo_sream
 *   x-bind="turbo"
 */
//  Alpine.bind('Turbo', () => ({
//   '@click'(e) {
//     e.preventDefault();

//     const url = this.$el.getAttribute("href")
//     fetch(url, {
//       headers: {
//         Accept: "text/vnd.turbo-stream.html"
//       }
//     })
//       .then(r => r.text())
//       .then(html => Turbo.renderStreamMessage(html))
//   }
// }));


/** ok
 * Remove o elemento html.
 * Aceita parametro em milisegundos.
 * 
 * Exemplo de uso: remover uma mensagem de alerta:
 *   <div class="alert alert-success" role="alert" x-bind="AutoCloseTimeout"></div>
 */
//  Alpine.bind('CloseTimeout', (milis = 10000) => ({
//   'x-init'() {
//     setTimeout(() => {
//       this.$el.remove()
//     }, milis);
//   }
// }));


/**
 * Fecha modal simulando click em data-bs-dismiss
 * 
 * Uso: <div x-bind="CloseModal"></div>
 */
// Alpine.bind('CloseModal', () => ({
//   'x-init'() {    
//     document.querySelectorAll('[data-bs-dismiss=modal]').forEach(el => el.click())
//     this.$el.remove()    
//     // const closes = document.querySelectorAll('[data-bs-dismiss]')
//     // closes.forEach((close) =>{
//     //   const isVisible = close && close.offsetWidth > 0 && close.offsetHeight > 0
//     //   if (close && isVisible) {
//     //     const tr = this.$el.closest('tr')
//     //     tr.classList.add('table-active')      
//     //     close.click()
//     //     this.$el.remove()
        
//     //     setTimeout(() => {
//     //       tr.classList.remove('table-active')
//     //     }, 10000);
//     //   }
//     // });
//   }
// }));


/**
 * Abre modal usando ao inicializar o no browser
 */
// Alpine.bind('OpenModal', () => ({
//   'x-init'() {
//     bootstrap.Modal.getOrCreateInstance(this.$el).show()
//   },
//   '@turbo:before-fetch-response'() {
//     // bootstrap.Modal.getOrCreateInstance(this.$el).hide()
//   }
// }));


/**
 * Atualiza o frame search após criação de um registro.
 */
// Alpine.bind('ReloadFrameSearch', () => ({
//   'x-init'() {    
//     let search = document.getElementById('search')
//     search.setAttribute('src', window.location.href)
//   }
// }));


// ########################################################################


// Alpine.start()
