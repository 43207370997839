import { Turbo } from "@hotwired/turbo-rails"
/**
 * Ex:
 * <button @onclick="$turboFetch('/url')" >Click</button>
 */
export default () => {
  return ((url, params={}) => {
    const emptyParams = Object.keys(params).length === 0 && params.constructor === Object;
    
    const csrfToken = document.getElementsByName("csrf-token")[0].content;
    const qs =  emptyParams ? '' : '?' + new URLSearchParams(params).toString();
    
    fetch(url + qs, {
      headers: {
        "X-CSRF-Token": csrfToken,
        Accept: "text/vnd.turbo-stream.html"
      }
    })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  })
}
