// Usage: <div x-data="Timeout"></div>

export default (milis=9000) => ({
  init() {
    this.timeout = setTimeout(() => {
      this.$dispatch('timeout')
    }, milis)
  },

  remove() {
    this.clear()
    this.$root.remove()
  },

  clear() {
    clearTimeout(this.timeout)
  }

})
