/*
- Usage: <div x-data="Numeral"></div>
- Formato saida: <input type="text" x-data="Numeral({out: 'YYYY'})" >
- Usado em: config/initialize/bootstrap_form_builder.rb

- Formatos:
    0.00    => 1000,00
    0.000   => 1000,000
    0,0.00  => 1.000,00
*/
export default (args = { out: '0.00' }) => ({
  _formatOut: args.out,
  inputHidden: null,

  init() {
    console.log("init:Numeral");
    this.$el.setAttribute('x-on:change', "handleChange");
    this.$el.setAttribute('x-on:focus', "handleFocus");

    // cria elmento auxiliar que armazena o valor que vai para o servidor
    this.inputHidden = document.createElement("input");
    this.inputHidden.setAttribute('type', 'hidden');
    this.inputHidden.setAttribute('name', this.$el.name);
    this.inputHidden.setAttribute('id', this.$el.id + "_hidden");
    this.inputHidden.setAttribute('value', this.$el.value);

    this.$el.removeAttribute('name');
    this.$el.removeAttribute('id');
    this.$el.insertAdjacentElement("beforebegin", this.inputHidden)


    this.$nextTick(() => {
      this.$dispatch('change', { init: true })
    });
  },

  handleChange(e) {
    const isInit = (e.detail && ('init' in e.detail));

    let value = e.target.value;

    if (!isInit) {
      value = this.get_value_as_us_format(value);
    }

    // Popula field que será enviado pelo formulário
    this.inputHidden.value = value;

    // Popula field que será exibido no formulário
    e.target.value = this.get_value_as_br_format(value);

    if (!isInit) {
      this.$dispatch('numeral-change', { target: e.target });
    }
  },

  handleFocus(e) {
    e.target.select();
  },

  get_value_as_br_format(value) {
    return numeral(parseFloat(value)).format(this._formatOut);
  },

  get_value_as_us_format(value) {
    value = value.toString().trim();

    const formatoBrasileiro = /^\d{1,3}(?:\.\d{3})*(,\d+)?$/;
    const formatoAmericano = /^\d+(\.\d+)?$/;

    if (formatoBrasileiro.test(value)) {
      return value.replace('.', '').replace(',', '.');
    }

    if (formatoAmericano.test(value)) {
      return value;
    }

    console.error("formato inválido: ", value);
    return '';
  }

})
