// Usage: <input type="text" x-data="Moment" />
// Formato saida: <input type="text" x-data="Moment({out: 'YYYY'})" >
// Usado em: config/initialize/bootstrap_form_builder.rb

import moment from "moment/moment";

export default (args={in: '', out: 'DD/MM/YYYY'}) => ({
  _formatOut: args.out,
  _formatIn: args.in,

  init() {
    this.$el.setAttribute('x-on:change', "handleChange");
    this.$el.setAttribute('x-on:focus', "handleFocus");
    this.$el.setAttribute('x-on:keypress', "handleKeyPress");

    this.$nextTick(()=>{
      this.$dispatch('change', {init: true})
    });
  },
  
  // Descobre o formato de entrada quando não informado.
  _currentFormatIn(date){
    const formats = [
      {format: 'DD-MM-YYYY', pattern: /^\d{2}-\d{2}-\d{4}/},
      {format: 'YYYY-MM-DD', pattern: /^\d{4}-\d{2}-\d{2}/},
      {format: 'YYYYMM', pattern: /^\d{6}/},
      {format: 'YYYY', pattern: /^\d{4}/}
    ]

    const defaultIn = formats[0];
    // Quando informado, usa o informado.
    if (this._formatIn) {
      return this._formatIn
    }

    let format = formats.find((f) => f.pattern.test(date)) || defaultIn;
    return format.format
  },

  handleChange(e) {
    let value = e.target.value;  
    const formatIn = this._currentFormatIn(value)

    if (this._formatOut == 'DD/MM/YYYY HH:mm') {
      if (value.length < 10){
        value = moment(value, 'DD/MM/YYYY').format('DD/MM/YYYY').replace('Data inválida', '');
        value = value + ' ' + moment().format('HH:mm');
      }
    }
    e.target.value = moment(value, formatIn).format(this._formatOut).replace('Data inválida', '');
    
    if (!e.detail || !('init' in e.detail)) {
      this.$dispatch('moment-change', {target: e.target});
    }
  },

  handleFocus(e) {
    e.target.select()
  },

  handleKeyPress(e) {  
    const value = e.target.value || moment().format(this._formatOut);  
    const formatIn = this._currentFormatIn(value)

    if (e.key == '=') {
      e.preventDefault();
      e.target.value = moment().format(this._formatOut);
      this.$dispatch('moment-change', {target: e.target});
    } else if (e.key == '-') {
      e.preventDefault();
      e.target.value = moment(value, formatIn).subtract(1, 'days').format(this._formatOut);
      this.$dispatch('moment-change', {target: e.target});
    } else if (e.key == '+') {
      e.preventDefault();
      e.target.value = moment(value, formatIn).add(1, 'days').format(this._formatOut);
      this.$dispatch('moment-change', {target: e.target});
    }
  }
})
